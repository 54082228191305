import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "What are Mike's favorite flaws/vices?",
    "How have you leveraged your technical depth to drive innovation?",
    "How would the CTO describe Mike?"
];

const GPT4V_EXAMPLES: string[] = [
    "How have you leveraged your technical depth to drive innovation and accelerate delivery timelines on your teams?",
    "How do you approach transparently sharing progress/blockers while maintaining team morale and forward momentum during stressful sprints?",
    "What is your rate?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).slice(0, window.innerWidth <= 600 ? 2 : undefined).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
